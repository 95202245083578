export const appendQueryParams = (url: string, params: Record<string, string>) => {
  // Check if the URL already has query parameters
  const hasQuery = url.includes('?');

  // Constructing the query string from the params object
  const queryString = Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

  // Append the query string to the URL
  if (hasQuery) {
    // If the URL already has query parameters, append with &
    return `${url}&${queryString}`;
  } else {
    // If the URL doesn't have query parameters, start with ?
    return `${url}?${queryString}`;
  }
}
