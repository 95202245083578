import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "./InputOTP";
import { useAppContext } from "../context/AppProvider";

export const Verification: React.FC<IProps> = (props) => {
  const { handleVerification, handleSignIn, email } = useAppContext();
  const [isVerifying, setVerifying] = useState(false);
  const [isResending, setResending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [resendErrorMessage, setResendErrorMessage] = useState<string>();

  const onTryAgainClick = async () => {
    setResending(true);
    try {
      await handleSignIn(email);
    } catch (error: any) {
      if (error?.message) setResendErrorMessage(error.message);
    }
    setVerifying(false);
    setResending(false);
  };

  const onCodeChange = async (value: string) => {
    setErrorMessage("");
    if (value.length === 6) {
      setVerifying(true);
      try {
        await handleVerification(value);
      } catch (error: any) {
        if (error?.message) setErrorMessage(error?.message);
      }

      setVerifying(false);
    }
  };

  return (
    <div className="w-full text-center">
      <h1 className="text-center text-slate-800 text-2xl font-semibold leading-9 mb-2 text-wrap">
        Enter your one-time password
      </h1>
      <p className="text-center text-neutral-500 text-base font-normal leading-relaxed mb-12">{email}</p>
      <div className="flex justify-center">
        <InputOTP
          maxLength={6}
          className=""
          disabled={isVerifying || isResending}
          onChange={onCodeChange}
        >
          <InputOTPGroup>
            <InputOTPSlot index={0} />
            <InputOTPSlot index={1} />
            <InputOTPSlot index={2} />

            <InputOTPSlot index={3} />
            <InputOTPSlot index={4} />
            <InputOTPSlot index={5} />
          </InputOTPGroup>
        </InputOTP>
      </div>
      {errorMessage && <div className="text-center">{errorMessage}</div>}

      <p className="text-center text-neutral-500 text-base font-normal leading-snug my-12">
        Didn&#39;t get the email? Don&#39;t forget to check your spam folder.
      </p>

      <Button
        variant="link"
        className="mx-auto"
        onClick={onTryAgainClick}
        disabled={isResending}
      >
        Try again
      </Button>
      {resendErrorMessage && (
        <div className="text-center">{resendErrorMessage}</div>
      )}
    </div>
  );
};

interface IProps {
}
