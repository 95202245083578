import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  confirmSignUp,
  resendSignUpCode,
  signUp,
  SignUpOutput,
} from "aws-amplify/auth";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormikTextField } from "@quadspire/sd-shared-frontend/lib/components/formFields/TextField";
import { FormikCheckboxField } from "@quadspire/sd-shared-frontend/lib/components/formFields/CheckboxField";
import { ButtonWithLoader } from "@quadspire/sd-shared-frontend/lib/components/ButtonWithLoader";
import { useAppContext } from "../context/AppProvider";
import { fetchJWT } from "../utils/fetchJWT";
import { useParams } from "react-router-dom";

export const SignupForm: React.FC = () => {
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [signupResponse, setSignupResponse] = useState<SignUpOutput>();
  const [fullName, setFullName] = useState("");
  const { type } = useParams();

  const handleSubmit = async (value: IFormValues) => {
    setIsSigningUp(true);
    setErrorMessage("");
    try {
      const response = await signUp({
        username: value.email,
        password: generateCognitoPassword(),
        options: {
          autoSignIn: true,
          userAttributes: {
            email: value.email,
            name: value.name,
            ["custom:usertype"]:
              type.toLowerCase() === "customer" ? "customer" : "seller", //value.customer_type || "seller"
          },
        },
      });

      setFullName(value.name);
      setSignupResponse(response);
      setUsername(value.email);
    } catch (err) {
      console.log(err);
      setErrorMessage((err as { message: string }).message);
    }
    setIsSigningUp(false);
  };

  if (signupResponse && !signupResponse.isSignUpComplete) {
    return <ConfirmationForm username={username} name={fullName} />;
  }

  return (
    <>
      <Button variant={`outline-info rounded-pill w-100 mb-3`} disabled>
        <i className="fi-google fs-lg me-1"></i>
        Sign in with Google
      </Button>
      <Button variant={`outline-info rounded-pill w-100 mb-3`} disabled>
        <i className="fi-facebook fs-lg me-1"></i>
        Sign in with Facebook
      </Button>
      <div className="d-flex align-items-center py-3 mb-3">
        <hr className="w-100" />
        <div className="px-3">Or</div>
        <hr className="w-100" />
      </div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValue}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit} onReset={props.handleReset}>
            <FormikTextField
              label="Full name"
              name="name"
              required
              placeholder="Enter your full name"
              className="mb-3"
              validateOnBlur
            />
            <FormikTextField
              label="Email address"
              name="email"
              required
              placeholder="Enter your email"
              className="mb-3"
              validateOnBlur
            />
            <FormikCheckboxField
              label={termsAndConditionsStatement}
              name="agree"
              className="mb-3"
            />
            <ButtonWithLoader
              type="submit"
              size="lg"
              variant={`primary rounded-pill w-100`}
              className="mt-4"
              isLoading={isSigningUp}
            >
              Sign up
            </ButtonWithLoader>
            <Form.Control.Feedback
              type="invalid"
              className="d-block text-center"
            >
              {errorMessage}
            </Form.Control.Feedback>
          </Form>
        )}
      </Formik>
    </>
  );
};

const initialValue: IFormValues = {
  email: "",
  agree: false,
  name: "",
};

const validationSchema = Yup.object({
  email: Yup.string().label("Email").email().required(),
  name: Yup.string().label("Full name").required(),
  agree: Yup.boolean().oneOf(
    [true],
    "You must agree to the terms and conditions"
  ),
});

const termsAndConditionsStatement = [
  <span key={1}>By joining, I agree to the </span>,
  <a key={2} href="#">
    Terms of use
  </a>,
  <span key={3}> and </span>,
  <a key={4} href="#">
    Privacy policy
  </a>,
];

interface IFormValues {
  email: string;
  name: string;
  agree: boolean;
}

export const ConfirmationForm: React.FC<{
  username: string;
  name: string;
}> = ({ username, name }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { handleConfirmCodeSubmit } = useAppContext();

  const onResendCode = async () => {
    setIsResending(true);
    try {
      await resendSignUpCode({
        username,
      });
    } catch (err) {
      console.log(err);
      setErrorMessage((err as { message: string }).message);
    }

    setIsResending(false);
  };
  const onConfirmCodeSubmit = async (value: { code: string }) => {
    setIsSubmitting(true);
    try {
      await handleConfirmCodeSubmit(username, value.code, name);
    } catch (err) {
      console.log(err);
      setErrorMessage((err as { message: string }).message);
    }
    setIsSubmitting(false);
  };
  return (
    <div>
      <h3 className="h3">We Emailed You</h3>
      <p>
        Your code is on the way. To log in, enter the code we emailed to&nbsp;
        {username}. It may take a minute to arrive.
      </p>

      <Formik
        onSubmit={onConfirmCodeSubmit}
        initialValues={confirmationFormInitialValue}
        validationSchema={confirmationCodeValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(props) => (
          <Form
            onSubmit={props.handleSubmit}
            onReset={props.handleReset}
            validated
          >
            <FormikTextField label="Confirmation Code" name="code" />
            <ButtonWithLoader
              type="submit"
              size="lg"
              variant={`primary rounded-pill w-100`}
              className="mt-4"
              isLoading={isSubmitting}
              disabled={isResending}
            >
              Confirm
            </ButtonWithLoader>
            <div className="text-center">
              <ButtonWithLoader
                size="lg"
                className="mt-4"
                variant="link"
                isLoading={isResending}
                disabled={isSubmitting}
                type="button"
                onClick={onResendCode}
              >
                Resend
              </ButtonWithLoader>
            </div>
            <Form.Control.Feedback
              type="invalid"
              className="d-block text-center"
            >
              {errorMessage}
            </Form.Control.Feedback>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const confirmationFormInitialValue = {
  code: "",
};

const confirmationCodeValidationSchema = Yup.object({
  code: Yup.string().label("Code").required(),
});

function generateCognitoPassword(length = 12) {
  const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
  const numberChars = "0123456789";
  const specialChars = "!@#$%^&*()_+[]{}|;:,.<>?";
  const allChars = upperCaseChars + lowerCaseChars + numberChars + specialChars;

  function getRandomChar(charSet: string) {
    const randomIndex =
      crypto.getRandomValues(new Uint32Array(1))[0] % charSet.length;
    return charSet[randomIndex];
  }

  // Ensure at least one character from each required set
  let password = [
    getRandomChar(upperCaseChars),
    getRandomChar(lowerCaseChars),
    getRandomChar(numberChars),
    getRandomChar(specialChars),
  ];

  // Fill the rest of the password with random characters from all character sets
  for (let i = password.length; i < length; i++) {
    password.push(getRandomChar(allChars));
  }

  // Shuffle the password to avoid predictable patterns
  return password.sort(() => Math.random() - 0.5).join("");
}
