import { AuthSession, fetchAuthSession, fetchUserAttributes } from "aws-amplify/auth";
import axios from "axios";

export const fetchJWT = async (
  session?: AuthSession,
  newUser?: {
    email: string;
    name: string;
  }
) => {
  session = session || (await fetchAuthSession());
  if (!session) {
    throw new Error("Authentication failed");
  }

  const userAttributes = await fetchUserAttributes()
  const token = session.tokens.accessToken.toString();
  if (newUser) {
    await axios
      .post(`${process.env.REACT_APP_API_SERVER}/users`, newUser, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        if (err.message === "Network Error") {
        }
        console.log(err);
        throw new Error(err.message);
      });
  }


  return {
    token,
    userType: userAttributes["custom:usertype"] as string,
  };
};
