import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Grid, Container, AppBar, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { Link } from "react-router-dom";

export const SignUpSelector = () => {
  return (
    <Container>
      <Grid container spacing={3} sx={{ paddingTop: 15 }}>
        <Grid item xs></Grid>
        <Grid item xs={8}>
          <Row>
            <Col>
              <Paper
                sx={{
                  p: "50px",
                  borderRadius: "20px",
                  textAlign: "center",
                  borderColor: "black",
                }}
                variant="outlined"
              >
                {/* <Typography sx={{ color: "text.secondary", fontSize: 24, mb: 5 }}> */}
                <Typography gutterBottom variant="h4" component="div">
                  Welcome to the supplydome platform
                </Typography>
                <Typography sx={{ color: "text.secondary", mb: 1 }}>
                  How would you like to start?
                </Typography>
              </Paper>
            </Col>
          </Row>
        </Grid>
        <Grid item xs></Grid>
      </Grid>

      {/* Options layout */}
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ paddingTop: 10 }}
      >
        <SignUpOption
          description={"Lorem ipsum buyer in 15 words"}
          title="Sign up as corporate buyer"
          path={`/signup/customer`}
        />
        <SignUpOption
          description={"Lorem ipsum buyer in 15 words"}
          title="Are you a supplier?"
          path={`/signup/supplier`}
        />
      </Grid>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ paddingTop: 10 }}
      >
        <SignUpOption
          description={"Lorem ipsum buyer in 15 words"}
          title="Sign up as individual user"
          path={`/signup/user`}
        />
        <SignUpOption
          description={"Lorem ipsum buyer in 15 words"}
          title="Are you a manufacturer?"
          path={`/signup/manufacturer`}
        />
      </Grid>
    </Container>
  );
};

const SignUpOption: React.FC<{
  title: string;
  description: string;
  path: string;
}> = ({ title, description, path }) => {
  return (
    <Grid container spacing={2} xs={6} sx={{ paddingX: 2 }}>
      <Grid item xs={12} sm container sx={{ borderBottom: 1 }}>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {description}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ cursor: "pointer" }} variant="body2"></Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Link to={path} aria-label="play/pause">
            <ArrowCircleRightOutlinedIcon sx={{ height: 38, width: 38 }} />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
