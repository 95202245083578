import { SignupForm } from "../components/SignupForm";
import { useParams } from "react-router-dom";

export const SignupPage: React.FC = () => {
  const { type } = useParams();
  return (
    <div className="row mx-0 align-items-center">
      <div className="col-md-6 border-end-md p-4 p-sm-5">
        <h2 className="h3 mb-4 mb-sm-5">
          Join now.
          <br />
          Become a verified {getLoginType(type)}
        </h2>
        <ul className="list-unstyled mb-4 mb-sm-5">
          <li className="d-flex mb-2">
            <i className="fi-check-circle text-primary mt-1 me-2"></i>
            <span>
              Access to verified customer, supplier and manufacturer network
            </span>
          </li>
          <li className="d-flex mb-2">
            <i className="fi-check-circle text-primary mt-1 me-2"></i>
            <span>Product awareness and inventory insights</span>
          </li>
          <li className="d-flex mb-0">
            <i className="fi-check-circle text-primary mt-1 me-2"></i>
            <span>RFQ's, demand generation and profiles</span>
          </li>
        </ul>
        <div className="d-flex justify-content-center">
          <img src="/images/signup.svg" alt="Illusration" />
        </div>
        <div className="mt-sm-4 pt-md-3">
          Already have an account? <a href="/login">Sign in</a>
        </div>
      </div>
      <div className="col-md-6 px-4 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
        <SignupForm />
      </div>
    </div>
  );
};

const getLoginType = (type: string) => {
  if (
    ["customer", "supplier", "user", "manufacturer"].includes(
      type.toLowerCase()
    )
  )
    return type.toLowerCase();
  return "user";
};
