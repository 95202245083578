import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Amplify } from "aws-amplify";
import {
  AuthSession,
  autoSignIn,
  confirmSignIn,
  confirmSignUp,
  fetchAuthSession,
  signIn,
} from "aws-amplify/auth";
import { useLocation, useSearchParams } from "react-router-dom";
import { Redirecting } from "../components/Redirecting";
import { setTokenCookie } from "../utils/setTokenCookie";
import { appendQueryParams } from "../utils/appendQueryParams";
import { fetchJWT } from "../utils/fetchJWT";
import axios from "axios";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      userPoolClientId:
        process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
      // identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
      signUpVerificationMethod: "code", // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        email: true,
      },
    },
  },
});

export const appContext = React.createContext<IAppContext>({
  setIsLoggedIn: undefined,
  token: undefined,
  setIsFirstLogin: undefined,
  handleSignIn: undefined,
  email: undefined,
  handleVerification: undefined,
  handleConfirmCodeSubmit: undefined,
});

interface IAppContext {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  token: string;
  setIsFirstLogin: () => void;
  handleSignIn: (email: string) => Promise<void>;
  email: string;
  handleVerification: (code: string) => Promise<void>;
  handleConfirmCodeSubmit: (
    username: string,
    code: string,
    name: string
  ) => Promise<void>;
}

type User = {
  [key in "customer" | "seller"]?: {
    acceptedAgreements?: { id: string }[]; // Assuming each agreement has an ID
  };
};

export const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [userType, setUserType] = useState<string>();
  const [token, setToken] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [user, setUser] = useState<User>();
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [initialRedirectUrl] = useState(
    searchParams.get("redirect") || process.env.REACT_APP_ADMIN_SITE_CALLBACK!
  );

  const redirectUrl = useMemo(() => {
    if (isLoggedIn && user && token) {
      const onBoardingCompleted = (user.customer || user.seller)
        ?.acceptedAgreements?.length;
      return appendQueryParams(
        onBoardingCompleted
          ? process.env.REACT_APP_ADMIN_SITE_CALLBACK!
          : searchParams.get("redirect") ||
              `${process.env.REACT_APP_SITE_URL!}/onboarding/${userType}`,
        { type: userType, token }
      );
    }
  }, [token, userType, initialRedirectUrl]);

  useEffect(() => {
    if (location.pathname.toLowerCase() === "/signout") {
      setInitializing(false);
      return;
    }

    fetchSession();
  }, [isLoggedIn]);

  const fetchSession = async (
    createUserCallback?: (token: string) => Promise<any>
  ) => {
    return fetchAuthSession()
      .then((session: AuthSession) => {
        setInitializing(false);
        if (!session?.tokens.accessToken) return;

        setIsLoggingIn(true);
        return fetchJWT(session).then(async (response) => {
          if (isFirstLogin) {
            await completeFirstLogin(response.token);
          }
          try {
            const currentUser = await getCurrentUser(response.token);
            setUser(currentUser);
          } catch (error) {
            if (!createUserCallback) throw new Error("User not exists");
            await createUserCallback(response.token);
            const currentUser = await getCurrentUser(response.token);
            setUser(currentUser);
          }

          setTokenCookie(response.token);
          setUserType(response.userType);
          setToken(response.token);
          setIsLoggedIn(true);
          setIsLoggingIn(false);

          return token;
        });
      })
      .catch((err: unknown) => {
        console.error(err);
        setInitializing(false);
      });
  };

  const handleSignIn = async (email: string) => {
    setEmail(email);
    await signIn({
      username: email,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
      },
    });
  };

  const handleVerification = async (code: string) => {
    await confirmSignIn({ challengeResponse: code });
    fetchSession();
  };

  const handleConfirmCodeSubmit = async (
    username: string,
    code: string,
    name: string
  ) => {
    await confirmSignUp({
      username,
      confirmationCode: code,
    });
    await autoSignIn();
    await fetchSession(async (token: string) => {
      return await axios
        .post(
          `${process.env.REACT_APP_API_SERVER}/users`,
          { email: username, name },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {})
        .catch((err) => {
          if (err.message === "Network Error") {
          }
          console.log(err);
          throw new Error(err.message);
        });
    });
  };

  const value: IAppContext = useMemo(
    () => ({
      setIsLoggedIn,
      handleSignIn,
      token,
      setIsFirstLogin: () => setIsFirstLogin(true),
      email,
      handleVerification,
      handleConfirmCodeSubmit,
    }),
    [setIsLoggedIn, setUserType, token, email]
  );

  if (initializing) return null;

  if ((isLoggedIn && redirectUrl) || isLoggingIn) {
    return <Redirecting redirectUrl={redirectUrl} isLoggingIn={isLoggingIn} />;
  }

  return <appContext.Provider value={value}>{children}</appContext.Provider>;
};

export const useAppContext = () => {
  const _appContext = useContext(appContext);

  return _appContext;
};

const getCurrentUser = async (token: string) => {
  return await axios
    .get(`${process.env.REACT_APP_API_SERVER}/users/me`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.data);
};

const completeFirstLogin = async (token: string) => {
  return await axios
    .post(
      `${process.env.REACT_APP_API_SERVER}/users/first-login`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data.data);
};

// preOnBoardingCompleted
