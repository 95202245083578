import Cookies from "js-cookie"

export const setTokenCookie = (token: string) => {
  Cookies.set("auth_token", token, {
    expires: 7,
    sameSite: 'Lax',
    secure: process.env.REACT_APP_ENV !== "dev",
    domain: process.env.REACT_APP_COOKIE_DOMAIN
  })
}

export const deleteTokenCookie = () => {
  Cookies.remove("auth_token", {
    sameSite: 'Lax',
    secure: process.env.REACT_APP_ENV !== "dev",
    domain: process.env.REACT_APP_COOKIE_DOMAIN
  })
}
