import { CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { Login } from "./pages/Login";
import Signout from "./pages/Signout";
import { SignupPage } from "./pages/SignupPage";
import { SignUpSelector } from "./pages/SignUpSelector";
import "./scss/theme.scss";
import "./styles.scss";
import { Layout } from "./components/Layout";

function App() {
  return (
    <div>
      <CssBaseline />
      <Layout>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route
              path="/signup-form"
              element={<SignupForm setAuth={setAuth} />}
            /> */}
          <Route path="/signup" element={<SignUpSelector />} />
          <Route path="/signup/:type" element={<SignupPage />} />
          <Route path="/signout" element={<Signout />} />
          <Route path="/" element={<SignUpSelector />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
