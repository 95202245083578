import React, { useEffect } from 'react';

import { useSearchParams } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import { signOut } from 'aws-amplify/auth';

import {deleteTokenCookie} from "../utils/setTokenCookie";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
      // identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
      signUpVerificationMethod: "code", // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        email: true,
      },
    },
  },
});

const Signout = () => {
  const [searchParams] = useSearchParams();

  const redirectUrl = searchParams.get("redirect")

  useEffect(() => {
    localStorage.clear()
    signOut()
    deleteTokenCookie()
    setTimeout(() => {
      window.location.href = redirectUrl || process.env.REACT_APP_PUBLIC_SITE
    }, 500)
  }, [])

  return (
    <div>Signing out...</div>
  );
};

export default Signout;
