import { PropsWithChildren } from "react";
import { AppProvider } from "../context/AppProvider";
export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <AppProvider>
      <div>
        <header className="py-4 mb-4 border-bottom">
          <div className="container">
            <img
              src="/images/LogoLight.svg"
              alt="Supplydome"
              style={{ height: 50 }}
            />
          </div>
        </header>
        <div className="container">{children}</div>
        <footer className="text-center py-1 mt-3 border-top">
          <div className="container">
            © All rights reserved. Quadspire Inc.
          </div>
        </footer>
      </div>
    </AppProvider>
  );
};
