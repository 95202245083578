import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { FormikTextField } from "@quadspire/sd-shared-frontend/lib/components/formFields/TextField";
import { ButtonWithLoader } from "@quadspire/sd-shared-frontend/lib/components/ButtonWithLoader";
import React, { useState } from "react";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import { useAppContext } from "../context/AppProvider";

export const LoginForm: React.FC<IProps> = ({ onSubmit }) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setIsLoggedIn, handleSignIn } = useAppContext();

  const handleSubmit = async (values: {
    username: string;
    password: string;
  }) => {
    setIsLoggingIn(true);
    setErrorMessage("");
    try {
      await handleSignIn(values.username);
      onSubmit();
      setIsLoggedIn(true);
    } catch (err) {
      console.log(err);
      setErrorMessage((err as { message: string }).message);
      setIsLoggedIn(false);
    }
    setIsLoggingIn(false);
  };

  return (
    <>
      <Button variant={`outline-info rounded-pill w-100 mb-3`} disabled>
        <i className="fi-google fs-lg me-1"></i>
        Sign in with Google
      </Button>
      <Button variant={`outline-info rounded-pill w-100 mb-3`} disabled>
        <i className="fi-facebook fs-lg me-1"></i>
        Sign in with Facebook
      </Button>
      <div className="d-flex align-items-center py-3 mb-3">
        <hr className="w-100" />
        <div className="px-3">Or</div>
        <hr className="w-100" />
      </div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValue}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(props) => (
          <Form
            onSubmit={props.handleSubmit}
            onReset={props.handleReset}
            validated
          >
            <FormikTextField
              label="Username"
              name="username"
              placeholder="Enter your username"
            />
            <ButtonWithLoader
              type="submit"
              size="lg"
              variant={`primary rounded-pill w-100`}
              className="mt-4"
              isLoading={isLoggingIn}
            >
              Sign in
            </ButtonWithLoader>

            <Form.Control.Feedback
              type="invalid"
              className="d-block text-center"
            >
              {errorMessage}
            </Form.Control.Feedback>
          </Form>
        )}
      </Formik>
    </>
  );
};

interface IProps {
  onSubmit: () => void;
}

const initialValue = {
  username: "",
  password: "",
};

const validationSchema = Yup.object({
  username: Yup.string().label("Username").required(),
});
