import { useState } from "react";
import { LoginForm } from "../components/LoginForm";
import { Link } from "react-router-dom";
import { Verification } from "../components/Verification";

export const Login = () => {
  const [stage, setStage] = useState<"login" | "verification">("login")
  return (
    <div className="row mx-0 align-items-center">
      <div className="col-md-6 border-end-md p-4 p-sm-5">
        <h2 className="h3 mb-4 mb-sm-5">
          Hey there!
          <br />
          Welcome back.
        </h2>
        <div className="d-flex justify-content-center">
          <img
            src="/images/signin.svg"
            width={344}
            height={292}
            alt="Illusration"
          />
        </div>
        <div className="mt-4 mt-sm-5">
          Don&apos;t have an account? <Link to="/">Sign up here</Link>
        </div>
      </div>
      <div className="col-md-6 px-4 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
        {stage === "login" ? <LoginForm onSubmit={() => setStage("verification")} /> : <Verification /> }
      </div>
    </div>
  );
};

interface IRenderFormProps {}
