import { useEffect } from "react";

export const Redirecting: React.FC<IProps> = ({ redirectUrl, isLoggingIn }) => {
  useEffect(() => {
    if (isLoggingIn) return;
    const timer = setTimeout(() => {
      window.location.href = redirectUrl;
    }, 1000);

    return () => clearTimeout(timer);
  }, [redirectUrl, isLoggingIn]);

  return (
    <div className="container text-center mt-5">
      <h2 className="h2">You will be redirected shortly...</h2>
      {!isLoggingIn && (
        <p>
          If you are not redirected, <a href={redirectUrl}>click here</a>.
        </p>
      )}
    </div>
  );
};

interface IProps {
  redirectUrl: string;
  isLoggingIn: boolean;
}
